
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { CheckBoxProps, CheckBoxResponse } from '@/constants/form/types';

@Component({})
export default class CheckBox extends Vue {
  // チェックBOX設定値
  @Prop({ default: [] })
  private checkBoxs!: Array<CheckBoxProps>;
  // チェックBOX色 (参考：https://vuetifyjs.com/ja/styles/colors/#sass-)
  @Prop({ default: '' })
  private color?: string;
  // 選択可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 生成チェックBOXの並び順
  @Prop({ default: 'start' })
  private justify?: string;
  // チェック状況
  private checkStatus: { [k: string]: boolean } = {};

  created() {
    // 初期設定知をチェック状況メンバに格納
    if (this.checkBoxs.length !== 0) {
      this.checkBoxs.forEach(box => {
        const name = String(box.name);
        this.checkStatus[name] = box.default ? true : false;
      });
    }
  }

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module checkBoxOpratipon
   * @param {string} name - 押下したチェックBOXのname
   * @return {[k: string]: boolean} - チェックBOXの名前とチェックしたか否か
   */
  @Emit('checkBoxOpratipon')
  private checkBoxOpratipon(name: string): CheckBoxResponse {
    this.checkStatus[name] = !this.checkStatus[name];
    return this.checkStatus;
  }
}
