









































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Icon from '@/components/common/elements/Icon.vue';
import Label from '@/components/common/elements/Label.vue';
import Button from '@/components/common/elements/Button.vue';
import MultipleLineText from '@/components/common/elements/MultipleLineText.vue';
import InputArea from '@/components/common/modules/InputArea.vue';
import DatePickerArea from '@/components/common/modules/DatePickerArea.vue';
import ComboBoxArea from '@/components/common/modules/ComboBoxArea.vue';
import SelectArea from '@/components/common/modules/SelectArea.vue';
import IconTextLink from '@/components/common/elements/IconTextLink.vue';

import AutoCreateForm from '@/components/form/AutoCreateForm.vue';

@Component({
  components: {
    SingleText,
    Icon,
    Label,
    Button,
    MultipleLineText,
    InputArea,
    DatePickerArea,
    ComboBoxArea,
    SelectArea,
    AutoCreateForm,
    IconTextLink
  }
})
export default class InputPanel extends Vue {
  @Prop({ default: () => [] })
  private fieldData: any;
  @Prop({ default: '' })
  private fieldCols: any;
  @Prop({ default: false })
  private isScrren?: boolean;
  // パネルのタイトル
  @Prop({ default: '' })
  private panelTitle!: string;
  // 表示ステータス
  @Prop({ default: '' })
  private displayStatus!: string;
  // 基本情報パネル開閉アイコン
  private arrowIcon = 'mdi-chevron-up';
  private isBasicAreaStatus = true;
  // 親コンポーネントと同期するための値
  @Prop({ default: () => ({}) })
  private parentRegistData: any;
  // 登録用データ
  private registData: any = {};

  /**
   * 子コンポーネントからEmitされる値を親に渡す
   * （値が渡されるか確認用）
   * @module catchInput
   * @param {any} response - 子コンポーネントより渡される値
   * @return {void} - Emit
   */
  @Emit('catchInput')
  private catchInput(response: any) {
    if (this.parentRegistData) {
      this.registData = { ...this.parentRegistData };
    }

    const name = response.name;
    const value = response.value;
    // ToDo:フォームのデータタイプに合わせて整形する必要あり
    this.registData[name] = value;
    this.sendData();
    return response;
  }

  /**
   * 子コンポーネントからEmitされた値をインサート・Update用に加工し親に渡す
   * @module sendData
   * @return {void} - Emit
   */
  @Emit('sendData')
  private sendData() {
    return this.registData;
  }

  @Emit('pushHandle')
  private pushHandle(data: any) {
    return data;
  }
}
