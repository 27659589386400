





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Button from '@/components/common/elements/Button.vue';

@Component({
  components: { SingleText, Button }
})
export default class EditAction extends Vue {
  @Prop({ default: '' })
  private label?: string;
  @Prop({ default: [] })
  private statusAction?: any;
  @Prop({ default: '' })
  private division?: string;

  /**
   * 子コンポーネントからEmitされる値を親コンポーネントに
   * （値が渡されるか確認用）
   * @module buttonAction
   * @param {string} type - 押下されたボタンのタイプ
   * @return {string} - 押下されたボタンのタイプ
   */
  @Emit('buttonAction')
  private buttonAction(type: string): string {
    return type;
  }
}
