
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

import hexabaseState from '@/store/hexabase';
import Setting from '@/models/settingModel';
import {
  FieldChildrenProp,
  StatusActions,
  ActionButtons,
  FieldsInfo,
  ItemActions,
  GetInputFieldsActions,
  StatusList
} from '@/services/hexabase/types';
import {
  employeeMasterForm,
  EmployeeRules,
  employeeGroupForm,
  employeeAccountRegisterForm,
  employeeAccountRegisterDialogForm
} from '@/constants/form/settingEmployeeForm';

import {
  EmployeeStatusList,
  EmployeeDetailDefine,
  Employeefields
} from '@/constants/setting/employee';
import { MemberList } from '@/constants/setting/types';
import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import EditAction from '@/components/setting/detail/EditAction.vue';
import InputPanel from '@/components/setting/detail/InputPanel.vue';
import ExecAction from '@/components/common/modules/ExecAction.vue';
import TransitionDialog from '@/components/common/modules/TransitionDialog.vue';
import Button from '@/components/common/elements/Button.vue';
import CheckBox from '@/components/common/elements/CheckBox.vue';
import InputArea from '@/components/common/modules/InputArea.vue';

import SingleText from '@/components/common/elements/SingleText.vue';
import AutoCreateForm from '@/components/form/AutoCreateForm.vue';

import IconTextLink from '@/components/common/elements/IconTextLink.vue';

import userState from '@/store/user';
import appState from '@/store/app';

import { DateTime } from 'luxon';

Component.registerHooks(['beforeRouteEnter']);
@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    EditAction,
    InputPanel,
    ExecAction,
    TransitionDialog,
    Button,
    CheckBox,
    InputArea,
    SingleText,
    AutoCreateForm,
    IconTextLink
  },
  beforeRouteEnter(to: Route, from: Route, next: any): void {
    next((vm: any) => {
      vm.prevRoute = from;
      next();
    });
  }
})
export default class SettingEmployeeEdit extends Vue {
  // model
  private setting = new Setting();
  // アプリケーションID
  private applicationId = hexabaseState.applicationId;
  // 社員マスタのデータストアのID
  private employeeMasterDsId = hexabaseState.datastoreIds['社員マスタ'];
  // アクションパネルのボタン
  private statusButton: Array<StatusActions> = [];
  // マスタデータ
  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};
  // 社員基本情報のデータ
  private fieldsData: FieldChildrenProp[] = [];
  // 社員基本情報のカラムデータ
  private fieldCols = '';
  // 社員のデータ
  private membersData: MemberList[] = [];
  // 更新用データ
  private updatePayload: any = { item: {}, rev_no: 0, return_item_result: true };

  private selectItemId = '';

  private selectAction = '';

  private dialogStatus = false;
  private bodyText = '';
  private button = [
    {
      id: 1,
      color: 'primary',
      type: 'reload',
      name: 'reload',
      value: '閉じる'
    }
  ];

  // コンポーネントで使用するタイトル等
  private actionPanelLabel = EmployeeDetailDefine.edit.actionPanelLabel;
  private execActionMessage = EmployeeDetailDefine.edit.message.execAction;
  private dialogTitle = EmployeeDetailDefine.edit.dialogTitle;

  // 編集対象アイテム情報
  private itemId = this.$route.params.id;
  // ログインアカウント連携情報パネルデータ
  private employeeAccountData = { cols: {}, datas: {} as { [k: string]: any } };
  // グループ登録情報パネルデータ
  private groupData = { cols: {}, datas: {} as { [k: string]: any } };
  // 担当グループ用セレクトデータ
  private divisionOptionsData: Array<{ [k: string]: any }> = [];
  // グループ登録時に使用するデータ
  private setGroupData = { operation_authority_name: '' };
  // グループ登録時に使用するデータ
  private setGroupBackupData = { operation_authority_name: '' };
  // 登録時に使用するg_idを格納
  private gIdData: Array<{ [k: string]: any }> = [];
  // 管理マスタの部署
  private definedValueArray: { [k: string]: any } = [];

  private groupTreeData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};

  private extractionGroupData: Array<{}> = [];

  private isAccountIssuanceButton = false;

  private registerAddGroupResponse: Array<{}> = [];

  // 社員マスタ用のダイアログステータス
  private settingEmployeeDialog?: boolean = false;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  private isPersistent?: boolean = true;

  private settingEmployeeDialogTitle?: string = '';
  private settingEmployeeDialogDescription?: string = '';

  private dialogData = { cols: {}, datas: {} as { [k: string]: any } };

  private execAllowActions: Array<ActionButtons> = [];

  private statusAction: Array<StatusActions> = [];
  private itemActions: Array<ItemActions> = [];
  // キャンセルボタン押下時の遷移時に使用
  private prevRoute: any = null;

  private currentData: Array<FieldsInfo> = [];

  private settingFields!: GetInputFieldsActions;
  // 詳細画面でグループに登録されている場合のユーザー情報(グループ削除でu_idを使用するため)
  private userData: any = {};
  // 詳細画面でグループに登録されている場合のユーザー情報(グループ削除でu_idを使用するため)
  private operationUser: any = {};
  // ステータス情報格納プロパティ
  private statusList: Array<StatusList> = [];
  private displayStatus = '';

  private operationAuthorityNameText = '';

  private checkEmailFlag = false;

  async mounted() {
    this.initial();
  }

  /**
   * 初期画面生成
   */
  public async initial() {
    try {
      this.$store.commit('hexabase/setIsLoading', true);
      await this.setting.loadMasterData();
      this.masterData = this.setting.getMaster();

      const [items, membersData, groupTreeData] = await Promise.all([
        this.setting.getLinkedItems(
          this.applicationId,
          this.employeeMasterDsId,
          this.itemId,
          '&use_display_id=true'
        ),
        this.setting.getDbMembers(),
        this.setting.getGroupTree()
      ]);

      this.operationUser = membersData.find(data => {
        if (userState.userEmail === data.email) {
          return data;
        }
      });

      this.currentData = items.field_values;
      Object.keys(items.status_actions).forEach(key => {
        this.statusAction.push(items.status_actions[key]);
      });
      Object.keys(items.item_actions).forEach(key => {
        this.itemActions.push(items.item_actions[key]);
      });

      this.execAllowActions = this.statusAction.filter(
        (v: any) => !v.display_id!.indexOf('employee_valid')
      ) as Array<ActionButtons>;
      this.statusButton = this.setting.statusButtonList(this.execAllowActions, this.currentData);

      // 指定アクションのフィールド情報を取得
      const initialAction = this.setting.getFormDisplayAction(this.itemActions, 'update');
      const inputFields = await this.setting.getFieldsByActionId(
        this.employeeMasterDsId,
        initialAction!.action_id
      );

      let fields = await this.setting.getField(
        this.applicationId,
        this.employeeMasterDsId,
        this.masterData,
        employeeMasterForm,
        [],
        inputFields,
        ''
      );

      // ステータスリストを取得
      const currentStatus = items.field_values.Status;

      if (this.setting.isNullOrUndefined(items.status_list)) {
        Object.keys(items.status_list).forEach(key => {
          this.statusList.push(items.status_list[key]);
        });
      }
      const currentStatusData = this.statusList.find(function(recode: any) {
        if (recode.display_id === currentStatus.value) {
          return recode;
        }
      });
      this.displayStatus = currentStatusData ? currentStatusData.status_name : 'ステータス取得失敗';
      const index = fields.findIndex(item => {
        if (item.display_id === currentStatus.field_id) {
          return item;
        }
      });

      if (index !== -1) {
        fields[index]['props']!['displayStatus'] = this.displayStatus;
      }

      fields = fields.map((field: any) => {
        if (field.dataType === 'dslookup') {
          field.props!.isBtnVisible = false;
        }

        employeeMasterForm.find((item: any) => {
          if (item.display_id === field.display_id) {
            if (item.show !== undefined) {
              field.show = item.show;
            } else {
              field.show = true;
            }
          }
        });

        if (items.field_values[field.display_id]) {
          field.props!.value = items.field_values[field.display_id].value;
        }
        field.props!.isReadonly = true;
        field.props!.isDisabled = true;
        return field;
      });

      this.fieldsData.push(
        ...this.setting.setFieldData(
          fields.filter(v => v.dataType !== 'status'),
          membersData
        )
      );
      this.fieldCols = this.setting.getGridStatus(fields);
      this.membersData.push(...membersData);
      // 社員マスタのrev_noを登録用に控える
      this.updatePayload['rev_no'] = items['rev_no'];

      // Hexabase登録されているメンバー情報の確認
      this.userData = membersData.find((item: any) => {
        if (items.field_values['activated_email_address'].value === item.email) {
          return item;
        }
      });

      // ログインアカウント連携
      this.employeeAccountData = this.getHeaderFields(
        JSON.parse(JSON.stringify(employeeAccountRegisterForm))
      );
      const employeeAccountSetData = {
        0: ''
      };
      this.getHeaderData(employeeAccountSetData, this.employeeAccountData);

      // HexaBaseのグループ登録済ユーザー情報がある場合は登録されているアドレスを表示
      if (this.userData !== undefined) {
        this.employeeAccountData.datas[0].props.value = this.userData['email'];
      } else {
        this.employeeAccountData.datas[0].props.value = items.field_values[
          'activated_email_address'
        ].value
          ? items.field_values['activated_email_address'].value
          : items.field_values['email'].value;
      }
      // グループ設定
      this.groupTreeData = groupTreeData.result;
      this.extractionGroupDataCreate(groupTreeData.result[0], this.extractionGroupData);
      this.groupData = this.getHeaderFields(JSON.parse(JSON.stringify(employeeGroupForm)));
      const setGroupData = {
        0: ''
      };
      this.getHeaderData(setGroupData, this.groupData);

      // 登録済みの操作権限名表示
      const userRegisterItem = Object.entries(
        appState.options['ユーザ登録']['登録画面']
      ).map(([key, text]) => ({ key, text }));

      for (const index in userRegisterItem) {
        const data = userRegisterItem[index];
        this.divisionOptionsData.push({ value: data.text, text: data.key });
      }

      this.groupData.datas[0].props.selectData.options = JSON.parse(
        JSON.stringify(this.divisionOptionsData)
      );

      const userDivision = this.divisionOptionsData.find((item: any) => {
        if (item.text === items.field_values.operation_authority_name.value) {
          return item;
        }
      });
      if (userDivision !== undefined) {
        this.groupData.datas[0].props.value = userDivision;
        this.setGroupData['operation_authority_name'] = userDivision.value;
        this.setGroupBackupData['operation_authority_name'] = userDivision.value;
      }

      // TODO 必須フィールド関係のエラー https://community.hexabase.com/t/topic/1053
      this.updatePayload.item['name'] = items.field_values['name'].value;
      this.updatePayload.item['email'] = items.field_values['email'].value;
    } catch (e) {
      this.setError(e);
    } finally {
      this.$store.commit('hexabase/setIsLoading', false);
    }
  }

  /**
   * アクションパネルのボタンを押下した際の次処理を進める
   * @param {string} - イベント情報として押下したボタン種類が返ってくる
   */
  private async buttonAction(type: any): Promise<void> {
    try {
      // ローディングをセット
      this.$store.commit('hexabase/setIsLoading', true);

      const copy = this.setting.modifyButtonProp(this.execAllowActions, type);
      const execAction = this.execAllowActions.find(action => action.display_id === type);
      this.selectAction = execAction ? execAction.display_id : '';
      this.updatePayload['action_id'] = type;
      switch (type) {
        case EmployeeStatusList['アカウント未発行']['アカウント発行']: {
          this.inuputCheck(this.employeeAccountData.datas[0].props, false, false, 'required|email');
          this.inuputCheck(this.groupData.datas[0].props, false, false, 'selectRequired');
          break;
        }
        case EmployeeStatusList['アカウント未発行']['編集する']:
        case EmployeeStatusList['アカウント発行済']['編集する']: {
          if (EmployeeStatusList['アカウント発行済']['編集する'] === type) {
            this.inuputCheck(this.groupData.datas[0].props, false, false, 'selectRequired');
          }
          this.fieldsData = (await this.setting.modifyFields(
            this.employeeMasterDsId,
            this.fieldsData,
            execAction!,
            EmployeeRules,
            this.masterData.m_employee.body
          )) as Array<FieldChildrenProp>;
          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      this.setError(error);
    } finally {
      this.statusButton = [
        ...this.setting.statusButtonList(this.execAllowActions, this.currentData, type)
      ];
      this.$store.commit('hexabase/setIsLoading', false);
    }
  }

  private inuputCheck(propsData: any, isReadonly = false, isDisabled = false, rules?: any) {
    propsData.isReadonly = isReadonly;
    propsData.isDisabled = isDisabled;
    propsData.rules = rules;
  }

  /**
   * 入力されているメールアドレスから
   * HexaBase登録ユーザーのアドレスを割り出します
   */
  suggest(): void {
    const result = this.setting.detailPageSuggest(this.fieldsData, this.membersData);
    if (result !== null) {
      this.$set(this.fieldsData[result.index].props!, 'value', result.value);
    }
  }

  /**
   * 自動生成要素からの入力を取得
   * @param input {[k:string]: string}
   * @returns void
   */
  private catchInput(input: { [k: string]: string }): void {
    this.updatePayload.item[input.name] = input.value;

    if (
      input.name === 'email' &&
      EmployeeStatusList['アカウント未発行']['編集する'] === this.updatePayload['action_id']
    ) {
      this.employeeAccountData.datas[0].props.value = input.value;
    }

    const index = this.fieldsData.findIndex(v => {
      if (v.display_id === input.name) {
        return v;
      }
    });
    switch (this.fieldsData[index].component) {
      case 'MasterSelectControl':
      case 'MasterSelectSetControl':
      case 'MasterSelectMultiControl':
      case 'MasterSelectMultiSetControl': {
        this.$set(this.fieldsData[index].props!, 'value', {
          i_id: input.value,
          item_id: input.value
        });
        break;
      }
      default: {
        this.$set(this.fieldsData[index].props!, 'value', input.value);
      }
    }
  }

  /**
   * アイテム情報の更新・キャンセルを実行します
   * @param type - 押下されたボタンの種類
   * @returns void
   */
  private async execAction(type: string): Promise<void> {
    switch (type) {
      case 'cancel': {
        this.$router.go(0);
        break;
      }
      case 'exec': {
        let result: any = [];
        switch (this.updatePayload['action_id']) {
          case EmployeeStatusList['アカウント無効']['アカウント再発行']: {
            try {
              this.$store.commit('hexabase/setIsLoading', true);
              const item = this.dataFindItem(
                this.employeeAccountData.datas,
                'display_id',
                'activated_email_address'
              );
              const addUserResult = await this.addGroupFunction(
                this.setGroupData,
                this.extractionGroupData,
                item.props.value
              );
              const date = DateTime.local();
              this.updatePayload.item['account_activation_date'] = date
                .toFormat('yyyy-MM-dd')
                .toString();
              result = await this.setting.changeStatus(
                this.applicationId,
                this.employeeMasterDsId,
                this.itemId,
                this.updatePayload['action_id'],
                this.updatePayload
              );
              if (result !== undefined && result.text !== undefined && result.text.length > 0) {
                this.bodyText = result.text;
              }
              this.setting.apiErrorHandle('EXECUTEACTION', result);
              this.setDialogMessage(EmployeeDetailDefine.edit.message.updateSuccess);
            } catch (e) {
              this.setDialogMessage(e as string);
            } finally {
              this.reset();
              this.$store.commit('hexabase/setIsLoading', false);
              this.setDialogStatus(true);
            }
            break;
          }
          case EmployeeStatusList['アカウント発行済']['アカウント無効']: {
            try {
              const removeUserResult = await this.removeGroupFunction(
                this.setGroupBackupData,
                this.extractionGroupData
              );
              this.$store.commit('hexabase/setIsLoading', true);
              const date = DateTime.local();
              this.updatePayload.item['account_invalidation_date'] = date
                .toFormat('yyyy-MM-dd')
                .toString();
              result = await this.setting.changeStatus(
                this.applicationId,
                this.employeeMasterDsId,
                this.itemId,
                this.updatePayload['action_id'],
                this.updatePayload
              );
              if (result !== undefined && result.text !== undefined && result.text.length > 0) {
                this.bodyText = result.text;
              }
              this.setting.apiErrorHandle('EXECUTEACTION', result);
              this.setDialogMessage(EmployeeDetailDefine.edit.message.updateSuccess);
            } catch (e) {
              this.setDialogMessage(e as string);
            } finally {
              this.reset();
              this.$store.commit('hexabase/setIsLoading', false);
              this.setDialogStatus(true);
            }
            break;
          }
          case EmployeeStatusList['アカウント未発行']['アカウント発行']: {
            this.accountIssuanceDialogOpen();
            break;
          }
          case EmployeeStatusList['アカウント発行済']['編集する']: {
            result = await this.accountIssuanceRegister(/*EmployeeStatusList['アカウント発行済']['編集する']*/);
            break;
          }
          case EmployeeStatusList['アカウント未発行']['編集する']: {
            try {
              this.$store.commit('hexabase/setIsLoading', true);
              if (
                Employeefields.userJoin.displayId in this.updatePayload.item &&
                this.updatePayload.item.u_id.length === 0
              ) {
                const activeDefine = Employeefields.activeAccount;
                const isActiveField = this.fieldsData.find(
                  v => v.display_id === activeDefine.displayId
                );
                if (isActiveField !== undefined) {
                  const inactive = isActiveField
                    .props!.selectData.options.map((v: { [k: string]: string }) => {
                      return v.display_id === activeDefine.value.inactive ? v.value : null;
                    })
                    .filter((v: string | null) => v)
                    .shift();
                  this.updatePayload.item[activeDefine.displayId] = inactive ? inactive : '';
                }
              }

              result = await this.setting.changeStatus(
                this.applicationId,
                this.employeeMasterDsId,
                this.itemId,
                this.updatePayload['action_id'],
                this.updatePayload
              );
              if (result !== undefined && result.text !== undefined && result.text.length > 0) {
                this.bodyText = result.text;
              }
              this.setting.apiErrorHandle('EXECUTEACTION', result);
              this.setDialogMessage(EmployeeDetailDefine.edit.message.updateSuccess);
            } catch (e) {
              this.setDialogMessage(e as string);
            } finally {
              this.reset();
              this.$store.commit('hexabase/setIsLoading', false);
              this.setDialogStatus(true);
            }
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private setSelectItemId(id: string) {
    this.selectItemId = id;
  }

  private setDialogStatus(flg: boolean) {
    this.dialogStatus = flg;
  }
  private setDialogMessage(str: string) {
    this.bodyText = str;
  }

  private reset() {
    this.setSelectItemId('');
    this.updatePayload = { ...this.updatePayload, ...{ item: {}, rev_no: 0 } };
  }

  private setError(errorMessage: any): void {
    if (typeof errorMessage === 'object') {
      if (errorMessage.message.indexOf('403') > -1) {
        this.bodyText =
          '実行権限がありません\n※アカウント発行・グループ変更等の操作は、\nシステム管理者権限の他にワークスペース管理者の権限が必要です。';
        this.setDialogStatus(true);
        return;
      }
      errorMessage = this.setting.getDefaultErrorMessage();
    }
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }

  /**
   * getGroupTreeで取得した内容を配列に格納する
   */
  private extractionGroupDataCreate(data: any, arrayData: Array<{}>) {
    if (data !== null && typeof data === 'object' && data.constructor === Object) {
      arrayData.push(data);
    }
    for (const key in data) {
      if (typeof data[key] === 'object') {
        this.extractionGroupDataCreate(data[key], arrayData);
      }
    }
  }

  /**
   * ヘッダ情報フィールド定義作成
   */
  private getHeaderFields(formData: any) {
    const baseData = {
      cols: {},
      datas: {} as { [k: string]: any }
    };
    try {
      baseData.cols = this.setting.getGridStatus(formData);
      baseData.datas = formData;
    } catch (errorMessage) {
      this.setError(errorMessage);
    }
    return baseData;
  }

  /**
   * ヘッダ情報フィールドデータ作成
   */
  private getHeaderData(setData: { [key: string]: any }, baseData: any) {
    try {
      Object.keys(setData).map((key: string) => {
        baseData.datas[key].props['value'] = setData[key];
      });
    } catch (errorMessage) {
      this.setError(errorMessage);
    }
  }

  /**
   * 自動生成要素からの入力を取得(グループ登録情報パネル側)
   * @param input {[k:string]: string}
   * @returns void
   */
  private catchInputgroupChange(input: { [k: string]: string }): void {
    switch (input.name) {
      case 'operation_authority_name': {
        const item = this.groupData.datas.find(function(recode: any) {
          if (recode.display_id === input.name) {
            return recode;
          }
        });
        item.props.value = input.value;
        // 社員マスタの「操作権限名」更新用
        this.operationAuthorityNameText = input.text;
        this.setGroupData[input.name] = input.value;
        break;
      }
      default: {
        break;
      }
    }
  }

  private accountIssuanceDialogOpen(str?: string) {
    this.settingEmployeeDialogTitle = '登録用モーダル';
    this.settingEmployeeDialogDescription = '下記の内容でアカウント登録を行います';
    this.dialogData = this.getHeaderFields(
      JSON.parse(JSON.stringify(employeeAccountRegisterDialogForm))
    );
    // Hexabaseに登録済みのメールアドレスかのチェック
    const checkEmail = this.membersData.find(item => {
      if (this.employeeAccountData.datas[0].props.value === item.email) {
        return item;
      }
    });
    if (checkEmail !== undefined) {
      this.checkEmailFlag = true;
    }
    //    "activated_email_address"
    const setGroupData = {
      0: this.employeeAccountData.datas[0].props.value,
      1: '',
      2: ''
    };
    this.getHeaderData(setGroupData, this.dialogData);

    const divisionItem = this.groupData.datas.find((item: any) => {
      if ('operation_authority_name' === item.display_id) return item;
    });
    const divisionOptionsItem = this.divisionOptionsData.find(item => {
      if (divisionItem.props.value === item.value) return item;
    });
    if (divisionOptionsItem !== undefined) {
      this.dialogData.datas[2].props.value = divisionOptionsItem.text;
    }

    this.settingEmployeeDialog = true;
  }

  private accountIssuanceDialogClose() {
    this.settingEmployeeDialogTitle = '';
    this.settingEmployeeDialogDescription = '';
    this.settingEmployeeDialog = false;
  }

  private catchDialogInput(input: { [k: string]: string }) {
    this.dialogData.datas[1].props.value = input.value;
  }

  private catchInputAccountEmail(input: { [k: string]: string }) {
    this.employeeAccountData.datas[0].props.value = input.value;
  }

  /**
   * 前画面に戻る処理
   */
  private backPreviousPage() {
    this.$router.push({ path: '/setting/employee/list', query: { searchConditions: 'true' }});
  }

  async accountIssuanceRegister(/*type:string*/) {
    const results: any = [];
    let errorStatus = false;
    try {
      if (this.settingEmployeeDialog === true) {
        this.accountIssuanceDialogClose();
      }

      this.$store.commit('hexabase/setIsLoading', true);

      const divisionName = this.setGroupData['operation_authority_name'].split(',');
      const addGroupName = divisionName[0];

      const addGroupData: any = this.extractionGroupData.find((item: any) => {
        if (item.display_id === addGroupName) {
          return item;
        }
      });
      if (
        EmployeeStatusList['アカウント未発行']['アカウント発行'] === this.updatePayload['action_id']
      ) {
        // メールアドレスの@より前をusernameとして、抜出
        const username = this.dialogData.datas[0].props.value.substr(
          0,
          this.dialogData.datas[0].props.value.indexOf('@')
        );

        const param = {
          email: this.dialogData.datas[0].props.value,
          g_id: addGroupData.id,
          username: username,
          no_confirm_email: true,
          tmp_password: this.dialogData.datas[1].props.value,
          invitor_id: this.operationUser.u_id,
          send_password_to_email: true,
          confirm_email_ack: true
        };

        const res = await this.setting.addUser(param);
        if (res !== undefined) {
          // 社員マスタにアカウント発行したEmail、操作権限名、アカウント有効化日を更新
          const activatedEmailAddress = this.dataFindItem(
            this.dialogData.datas,
            'display_id',
            'activated_email_address'
          );
          const operationAuthorityName = this.dataFindItem(
            this.dialogData.datas,
            'display_id',
            'operation_authority_name'
          );
          this.updatePayload.item['activated_email_address'] = activatedEmailAddress.props.value;
          this.updatePayload.item['operation_authority_name'] = operationAuthorityName.props.value;
          const date = DateTime.local();
          this.updatePayload.item['account_activation_date'] = date
            .toFormat('yyyy-MM-dd')
            .toString();
        }
      } else {
        if (this.operationAuthorityNameText !== '') {
          this.updatePayload.item['operation_authority_name'] = this.operationAuthorityNameText;
        }
      }

      // グループに変更の確認
      if (JSON.stringify(this.setGroupBackupData) !== JSON.stringify(this.setGroupData)) {
        const removeUserResult = await this.removeGroupFunction(
          this.setGroupBackupData,
          this.extractionGroupData
        );
        const item = this.dataFindItem(
          this.employeeAccountData.datas,
          'display_id',
          'activated_email_address'
        );
        const addUserResult = await this.addGroupFunction(
          this.setGroupData,
          this.extractionGroupData,
          item.props.value
        );
      }

      // アカウント登録、グループ更新後、社員マスタを更新する
      const changeStatusResults = await this.setting.changeStatus(
        this.applicationId,
        this.employeeMasterDsId,
        this.itemId,
        this.updatePayload['action_id'],
        this.updatePayload
      );
      if (
        changeStatusResults !== undefined &&
        changeStatusResults.text !== undefined &&
        changeStatusResults.text.length > 0
      ) {
        this.bodyText = changeStatusResults.text;
      }
      this.setting.apiErrorHandle('EXECUTEACTION', changeStatusResults);

      this.reset();
    } catch (e) {
      errorStatus = true;
      this.setError(e);
    } finally {
      this.$store.commit('hexabase/setIsLoading', false);
      if (errorStatus !== true) {
        if (this.selectAction === EmployeeStatusList['アカウント未発行']['アカウント発行']) {
          this.setDialogMessage(EmployeeDetailDefine.edit.message.accountRegisterSuccess);
        } else {
          this.setDialogMessage(EmployeeDetailDefine.edit.message.updateSuccess);
        }
        this.setDialogStatus(true);
      }
    }
    return results;
  }

  private dataFindItem(
    findData: any,
    findDataString: string,
    findConditionsItem: string | number,
    isEquivalent = true
  ) {
    const item = findData.find((item: any) => {
      if (isEquivalent === true) {
        if (item[findDataString] === findConditionsItem) {
          return item;
        }
      } else {
        if (item[findDataString] !== findConditionsItem) {
          return item;
        }
      }
    });
    return item;
  }

  // グループ設定データからユーザーを削除
  async removeGroupFunction(baseGroupData: any, hexabaseGroupData: any) {
    const results: any = [];
    const deletePromises: any = [];
    const divisionName = baseGroupData['operation_authority_name'].split(',');
    for (const index in divisionName) {
      const groupName = divisionName[index];
      const groupData: any = hexabaseGroupData.find((item: any) => {
        if (item.display_id === groupName) {
          return item;
        }
      });
      if (this.userData !== undefined) {
        deletePromises.push(
          this.setting.removeUser({
            u_id: this.userData.u_id,
            g_id: groupData.id
          })
        );
      }
    }
    results.push(await Promise.all(deletePromises));
    return results;
  }

  // グループ設定データからユーザーを追加
  async addGroupFunction(baseGroupData: any, hexabaseGroupData: any, addEmail: string) {
    const results: any = [];
    const addPromises: any = [];
    const divisionName = baseGroupData['operation_authority_name'].split(',');
    for (const index in divisionName) {
      const groupName = divisionName[index];
      const groupData: any = hexabaseGroupData.find((item: any) => {
        if (item.display_id === groupName) {
          return item;
        }
      });

      addPromises.push(
        this.setting.addUser({
          email: addEmail,
          g_id: groupData.id
        })
      );
    }
    results.push(await Promise.all(addPromises));
    return results;
  }
}
